@import url('https://fonts.google.com/specimen/DM+Sans');

Skip to content


{
    margin: 0;
    box-sizing: border-box;
}
body {
    font-family: 'Aclonica';
}
li {
    list-style: none;
}
a {
    text-decoration: none;
    color: white;
    font-family: 'DM Sans';
}

a:hover{
    color: gray;
}

.nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 8vh;
    background: rgb(0, 33, 65);
}
.nav__brand {
    text-transform: uppercase;
}

.logo_header{
    display: flex;
    flex-direction: row;
    align-items: center;

}

.nav__menu {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 3rem;
}
.nav__toggler {
    display: none;
}
.nav__toggler div {
    width: 2.5rem;
    height: 0.2rem;
    margin: 0.4rem;
    background: rgb(204, 204, 204);
    transition: 0.3s ease-in;
}

@media screen and (max-width: 768px) {
    .nav__toggler {
        display: block;
        cursor: pointer;
    }
    .nav__menu {
        position: fixed;
        top: 7vh;
        right: 0;
        height: 93vh;
        width: 50vw;
        background: rgb(0, 33, 65);
        flex-direction: column;
        transform: translateX(100%);
        transition: 0.5s ease-in;
}
}

.nav__active {
    transform: translateX(0%);
}


.toggle .line1 {
    transform: rotate(-45deg) translate(-4px, 5px);
}
.toggle .line2 {
    opacity: 0;
}
.toggle .line3 {
    transform: rotate(45deg) translate(-4px, -5px);
}