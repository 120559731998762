@import url('https://fonts.google.com/specimen/DM+Sans');

body{
    font-family: 'DM Sans';
}

.hover:hover{
    transform: scale(1.15);
}

.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    width: 50vw;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    margin-right: auto;
    margin-left: auto;
}

.styled-table2 {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    width: 25vw;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    margin-right: auto;
    margin-left: auto;
}

.styled-table thead tr {
    background-color: #022141;
    color: #ffffff;
}

.styled-table2 thead tr {
    background-color: #022141;
    color: #ffffff;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
    align-items: center;
    vertical-align: center;
}

.styled-table2 th,
.styled-table2 td {
    padding: 12px 15px;
    align-items: center;
    vertical-align: center;
}


.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table2 tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table2 tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #022141;
}

.styled-table2 tbody tr:last-of-type {
    border-bottom: 2px solid #022141;
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: #022141;
}

.styled-table2 tbody tr.active-row {
    font-weight: bold;
    color: #022141;
}

.text{
    color: black;
}

.text a{
    color: blue;
    text-decoration: underline;
}

.textHolder{
    text-align: justify;
    text-justify: inter-word;
}

@media screen and (max-width: 768px) {
    .desktop{
    display: none;
    }
    .inputHeader{
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 60vw;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        flex-wrap: wrap;
    }
}


.inputHeader{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60vw;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
}