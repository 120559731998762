@import url('https://fonts.google.com/specimen/DM+Sans');


body{
    font-family: 'DM Sans';
}

.swapBox{
    margin-top: 2vh;
    color: white;
    display: flex;
    width: 30vw;
    margin-left: auto;
    margin-right: auto;
    height: 35vh;
    min-height: fit-content;
    min-width: fit-content;
    background-color: #0d111c;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #565d6f;
}

.swapBox:hover{
    transform: scale(1.02);
    box-shadow: 5px 5px 5px #565d6f;
}

.swapHolder{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.token_select{
    padding:5px;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: fit-content;
}

.token_select:hover{
    background-color: #464646;
    cursor: pointer;
    border-radius: 10px;
}


.addyHolder{
    background-color: #0d111c;
    width: 18vw;
    min-width: fit-content;
    float: right;
    margin: 15px;
    color: white;
    border-radius: 10px;
    padding: 5px;
}

.modalHolder{
    background-color: #0d111c;
    max-width: 32vw;
    min-width: fit-content;
    padding: 5%;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    max-height: 50vh;
    overflow: scroll;
    color: whitesmoke;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tokenSearch{
    width: 50%;
    height: 2.8rem;
    background: #f5f5f5;
    outline: none;
    border: none;
    border-radius: 1.625rem;
    padding: 0 3.5rem 0 1.5rem;
    font-size: 1rem;
}

.searchHolder{
    display: flex;
    width: 80%;
    justify-content: center;
    align-items: center;
}

.eachToken{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin: auto;
}

.center{
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.modalTop{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.btn-close{
    height: fit-content;
    width: auto;
    padding: 5px;
    border-radius: 2px;
    background-color: red;
}

.optionToken{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 5vh;
}

.optionToken img{
    border-radius: 50%;
}

.optionToken:hover{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height: 5vh;
    opacity: 0.6;
}


.form-control {
    border: 0;
    height: fit-content;
    width: 50%;
    padding: 3px 10px;
    box-shadow: 0px 4px 0px #DDD;
    transition: box-shadow 100ms ease-in;
    margin-bottom: 4px;
}

.form-control:focus {
    box-shadow: 0px 4px 0px #BCBCBC;
    outline: none;
}

.token_img{
    border-radius: 50%;
}